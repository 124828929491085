import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Mic, Video, Headphones, ChevronRight, Wand2 } from 'lucide-react';

const Button: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'custom';
  className?: string;
}> = ({ children, onClick, variant = 'primary', className = '' }) => {
  const baseClasses = 'px-6 py-3 rounded-md font-semibold text-lg transition-all duration-300 ease-in-out flex items-center';
  const variantClasses = {
    primary: 'bg-teal-500 text-white hover:bg-teal-600',
    secondary: 'bg-gray-800 text-teal-300 hover:bg-gray-700',
    custom: ''
  };

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]} ${className}`}
    >
      {children}
    </button>
  );
};

const FeatureCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  description: string;
}> = ({ icon, title, description }) => (
  <motion.div
    className="bg-gray-800 rounded-xl p-6 shadow-lg border border-teal-500/30"
    whileHover={{ scale: 1.05, borderColor: 'rgba(20, 184, 166, 0.7)' }}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    <div className="text-teal-400 mb-4">{icon}</div>
    <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

const AnimatedBackground: React.FC = () => {
  return (
    <div className="fixed inset-0 z-0">
      {[...Array(50)].map((_, i) => (
        <div
          key={i}
          className="absolute bg-teal-500 rounded-full opacity-10 animate-float"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            width: `${Math.random() * 100 + 50}px`,
            height: `${Math.random() * 100 + 50}px`,
            animationDuration: `${Math.random() * 20 + 10}s`,
            animationDelay: `${Math.random() * -20}s`,
          }}
        />
      ))}
    </div>
  );
};

const InteractiveDemo: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Upload', 'Process', 'Download'];

  return (
    <div className="bg-gray-800 rounded-xl p-8 relative overflow-hidden">
      <div className="flex justify-between mb-8">
        {steps.map((step, index) => (
          <div
            key={step}
            className={`text-center ${index <= activeStep ? 'text-teal-400' : 'text-gray-500'}`}
          >
            <div className="w-8 h-8 rounded-full border-2 flex items-center justify-center mx-auto mb-2">
              {index < activeStep ? '✓' : index + 1}
            </div>
            <div>{step}</div>
          </div>
        ))}
      </div>
      <Button
        onClick={() => setActiveStep((prev) => (prev < 2 ? prev + 1 : 0))}
        className="w-full justify-center"
      >
        {activeStep === 2 ? 'Restart Demo' : 'Next Step'}
      </Button>
    </div>
  );
};

const Land4: React.FC = () => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <div className="min-h-screen bg-gray-900 text-white relative overflow-hidden">
      <AnimatedBackground />
      <div className="relative z-10">
        {/* Hero Section */}
        <section className="container mx-auto px-4 py-20">
          <div className="flex flex-col lg:flex-row items-center">
            <motion.div
              className="lg:w-1/2 mb-10 lg:mb-0"
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6">
                Redefine Your <span className="text-teal-400">Voice</span>
              </h1>
              <p className="text-xl md:text-2xl mb-8 text-gray-300">
                Higgins Labs: Where AI meets crystal-clear communication
              </p>
              <div className="flex space-x-4">
                <Button onClick={() => console.log('Get Started clicked')}>
                  Get Started <ChevronRight className="ml-2" />
                </Button>
                <Button variant="secondary" onClick={() => console.log('Learn More clicked')}>
                  Learn More
                </Button>
              </div>
            </motion.div>
            <motion.div
              className="lg:w-1/2 w-full"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <InteractiveDemo />
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section className="container mx-auto px-4 py-20">
          <h2 className="text-4xl font-bold text-center mb-12">Unleash Your Potential</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Mic size={48} />}
              title="Accent Mastery"
              description="Refine your accent for global communication"
            />
            <FeatureCard
              icon={<Headphones size={48} />}
              title="Podcast Perfection"
              description="Elevate your podcast with studio-quality audio"
            />
            <FeatureCard
              icon={<Video size={48} />}
              title="Video Brilliance"
              description="Captivate your audience with clear, crisp dialogue"
            />
          </div>
        </section>

        {/* CTA Section */}
        <section className="container mx-auto px-4 py-20">
          <motion.div
            className="bg-gradient-to-r from-teal-500 to-blue-500 rounded-xl p-12 text-center relative overflow-hidden"
            whileHover={{ scale: 1.02 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <div className="relative z-10">
              <h2 className="text-4xl font-bold mb-4">Ready to Transform Your Content?</h2>
              <p className="text-xl mb-8">Join Higgins Labs and unlock the power of perfect speech!</p>
              <Button
                onClick={() => console.log('Start Free Trial clicked')}
                variant="custom"
                className="bg-white text-teal-600 hover:bg-gray-200 inline-flex"
              >
                Start Free Trial <Wand2 className="ml-2" />
              </Button>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-teal-600 to-blue-600 opacity-50" />
          </motion.div>
        </section>

        {/* Footer */}
        <footer className="bg-gray-800 text-gray-300 py-8">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between items-center">
              <div className="w-full md:w-auto text-center md:text-left mb-4 md:mb-0">
                <h3 className="text-2xl font-bold text-teal-400">Higgins Labs</h3>
                <p>Elevating voices, enhancing clarity</p>
              </div>
              <div className="w-full md:w-auto text-center md:text-right">
                <a href="#" className="text-gray-300 hover:text-teal-400 mx-2">Terms</a>
                <a href="#" className="text-gray-300 hover:text-teal-400 mx-2">Privacy</a>
                <a href="#" className="text-gray-300 hover:text-teal-400 mx-2">Contact</a>
              </div>
            </div>
            <div className="mt-4 text-center text-sm">
              © 2023 Higgins Labs. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Land4;