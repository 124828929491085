import { useState } from 'react'
import { motion } from 'framer-motion'
import { Mic, Video, Zap, Globe, ChevronRight } from 'lucide-react'
// import { Button } from "@/components/ui/button"

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  size?: string;
  className?: string;
}

function Button({ children, onClick, size, className }: ButtonProps) {
  return (
    <button
        onClick={onClick}
        className={`${className} px-6 py-2 rounded-lg transition-colors font-bold inline-flex items-center`}>
            {children}
    </button>
  )
}

export default function LandingPage() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-600 to-indigo-900 text-white">
      {/* Header */}
      <header className="container mx-auto py-6 flex justify-between items-center">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold">Higgins Labs</h1>
        </motion.div>
        <nav>
          <ul className="flex space-x-6">
            <motion.li
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <a href="#features">Features</a>
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <a href="#testimonials">Testimonials</a>
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <a href="#contact">Contact</a>
            </motion.li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="container mx-auto mt-20 text-center">
        <motion.h2
          className="text-5xl font-bold mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Speak Clearly, Be Heard Globally
        </motion.h2>
        <motion.p
          className="text-xl mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Remove accents, improve clarity, and amplify your voice in podcasts and videos
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          <Button size="lg" className="bg-yellow-400 text-purple-900 hover:bg-yellow-300">
            Try It Free <ChevronRight className="ml-2" />
          </Button>
        </motion.div>
      </section>

      {/* Animated Visual */}
      <motion.div
        className="container mx-auto mt-20 flex justify-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1.2, duration: 1 }}
      >
        <div className="relative w-96 h-96 bg-indigo-700 rounded-full flex items-center justify-center">
          <motion.div
            className="absolute w-32 h-32 bg-yellow-400 rounded-full"
            animate={{
              scale: [1, 1.2, 1],
              x: [0, 50, -50, 0],
              y: [0, -50, 50, 0],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
          <motion.div
            className="absolute w-24 h-24 bg-green-400 rounded-full"
            animate={{
              scale: [1, 1.3, 1],
              x: [0, -60, 60, 0],
              y: [0, 60, -60, 0],
            }}
            transition={{
              duration: 12,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
          <Mic size={64} className="text-white z-10" />
        </div>
      </motion.div>

      {/* Features Section */}
      <section id="features" className="container mx-auto mt-32">
        <h3 className="text-3xl font-bold text-center mb-12">Why Choose Higgins Labs?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard
            icon={<Mic size={32} />}
            title="Accent Removal"
            description="Our AI technology intelligently removes accents while preserving the speaker's unique voice."
          />
          <FeatureCard
            icon={<Video size={32} />}
            title="Video Enhancement"
            description="Improve the clarity of your video content, making it accessible to a global audience."
          />
          <FeatureCard
            icon={<Zap size={32} />}
            title="Real-time Processing"
            description="Experience instant results with our powerful real-time audio processing capabilities."
          />
        </div>
      </section>

      {/* Call to Action */}
      <section className="container mx-auto mt-32 text-center">
        <h3 className="text-3xl font-bold mb-6">Ready to Amplify Your Voice?</h3>
        <p className="text-xl mb-8">Join thousands of content creators who trust Higgins Labs</p>
        <Button size="lg" className="bg-green-400 text-purple-900 hover:bg-green-300">
          Start Your Free Trial
        </Button>
      </section>

      {/* Testimonials */}
      <section id="testimonials" className="container mx-auto mt-32">
        <h3 className="text-3xl font-bold text-center mb-12">What Our Users Say</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <TestimonialCard
            quote="Higgins Labs has revolutionized my podcast. My international audience can now understand me perfectly!"
            author="Sarah J., Podcast Host"
          />
          <TestimonialCard
            quote="As a non-native English speaker, this tool has been a game-changer for my YouTube channel."
            author="Miguel R., YouTuber"
          />
        </div>
      </section>

      {/* Footer */}
      <footer id="contact" className="mt-32 bg-indigo-800 py-12">
        <div className="container mx-auto text-center">
          <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
          <p className="mb-4">Email: info@higginslabs.com</p>
          <p className="mb-4">Phone: (555) 123-4567</p>
          <p>&copy; 2023 Higgins Labs. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <motion.div
      className="bg-white bg-opacity-10 p-6 rounded-lg"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="text-yellow-400 mb-4">{icon}</div>
      <h4 className="text-xl font-semibold mb-2">{title}</h4>
      <p className="text-gray-300">{description}</p>
    </motion.div>
  )
}

function TestimonialCard({ quote, author }: { quote: string; author: string }) {
  return (
    <motion.div
      className="bg-white bg-opacity-10 p-6 rounded-lg"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <p className="text-lg mb-4">"{quote}"</p>
      <p className="text-yellow-400 font-semibold">- {author}</p>
    </motion.div>
  )
}