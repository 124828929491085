import { useState } from 'react'
import { motion } from 'framer-motion'
import { Mic, Headphones, Video, Zap } from 'lucide-react'

export default function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-teal-400 text-white">
      <header className="container mx-auto px-4 py-6">
        <nav className="flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="text-2xl font-bold"
          >
            Higgins Labs
          </motion.div>
          <div className="hidden md:flex space-x-4">
            <a href="#features" className="hover:text-purple-200 transition-colors">Features</a>
            <a href="#how-it-works" className="hover:text-purple-200 transition-colors">How It Works</a>
            <a href="#pricing" className="hover:text-purple-200 transition-colors">Pricing</a>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-white focus:outline-none">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
        </nav>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden mt-4 space-y-2"
          >
            <a href="#features" className="block hover:text-purple-200 transition-colors">Features</a>
            <a href="#how-it-works" className="block hover:text-purple-200 transition-colors">How It Works</a>
            <a href="#pricing" className="block hover:text-purple-200 transition-colors">Pricing</a>
          </motion.div>
        )}
      </header>

      <main className="container mx-auto px-4 py-12">
        <section className="text-center mb-20">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-5xl md:text-6xl font-bold mb-6"
          >
            Crystal Clear Speech for Your Content
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8"
          >
            Remove accents and improve speech clarity in your podcasts and videos
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-purple-600 font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:bg-purple-100 transition-colors"
          >
            Get Started Free
          </motion.button>
        </section>

        <section id="features" className="grid md:grid-cols-2 gap-12 mb-20">
          <FeatureCard
            icon={<Mic className="w-12 h-12 text-purple-300" />}
            title="Accent Removal"
            description="Our AI technology intelligently removes accents while preserving the speaker's unique voice."
          />
          <FeatureCard
            icon={<Headphones className="w-12 h-12 text-teal-300" />}
            title="Enhanced Clarity"
            description="Improve the overall clarity of speech, making your content more accessible to a wider audience."
          />
          <FeatureCard
            icon={<Video className="w-12 h-12 text-purple-300" />}
            title="Video Integration"
            description="Seamlessly process both audio and video content with our easy-to-use platform."
          />
          <FeatureCard
            icon={<Zap className="w-12 h-12 text-teal-300" />}
            title="Real-time Processing"
            description="Experience lightning-fast processing times, allowing for quick turnaround on your projects."
          />
        </section>

        <section id="how-it-works" className="text-center mb-20">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">How It Works</h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-12">
            <Step number={1} description="Upload your audio or video" />
            <Step number={2} description="Our AI processes the content" />
            <Step number={3} description="Download your enhanced media" />
          </div>
        </section>

        <section id="pricing" className="text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Simple Pricing</h2>
          <div className="bg-white text-purple-600 rounded-lg shadow-xl p-8 max-w-md mx-auto">
            <h3 className="text-2xl font-bold mb-4">Pro Plan</h3>
            <p className="text-4xl font-bold mb-6">$29<span className="text-xl font-normal">/month</span></p>
            <ul className="text-left mb-8 space-y-2">
              <li>✅ Unlimited audio processing</li>
              <li>✅ Up to 10 hours of video processing</li>
              <li>✅ Priority support</li>
              <li>✅ Advanced analytics</li>
            </ul>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-purple-600 text-white font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:bg-purple-700 transition-colors"
            >
              Start Your Free Trial
            </motion.button>
          </div>
        </section>
      </main>

      <footer className="bg-purple-800 text-white py-8 mt-20">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Higgins Labs. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white bg-opacity-20 rounded-lg p-6 backdrop-blur-lg"
    >
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p>{description}</p>
    </motion.div>
  )
}

function Step({ number, description }: { number: number; description: string }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center"
    >
      <div className="bg-white text-purple-600 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold mb-2">
        {number}
      </div>
      <p className="text-lg">{description}</p>
    </motion.div>
  )
}