import React from 'react';
import { motion } from 'framer-motion';
import { Wrench, Clock, AlertTriangle } from 'lucide-react';

const MaintenancePage: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center min-h-[60vh] text-center px-4"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
        className="mb-8"
      >
        <Wrench className="w-24 h-24 text-indigo-600" />
      </motion.div>
      <h2 className="text-3xl font-bold text-gray-900 mb-4">
        We're Currently Under Maintenance
      </h2>
      <p className="text-xl text-gray-600 mb-8 max-w-md">
        We're working hard to improve our services and will be back soon!
      </p>
      <div className="flex items-center justify-center space-x-4 text-gray-500">
        {/* <div className="flex items-center">
          <Clock className="w-5 h-5 mr-2" />
          <span>Estimated downtime: 2 hours</span>
        </div> */}
        <div className="flex items-center">
          <AlertTriangle className="w-5 h-5 mr-2" />
          <span>We apologize for any inconvenience</span>
        </div>
      </div>
    </motion.div>
  );
};

export default MaintenancePage;
